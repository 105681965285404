<template>
  <NuxtLayout name="base">
    <div class="base">
      <slot />
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use 'pkg:@temperworks/styles/colors' as *;
@use 'pkg:@temperworks/styles/breakpoints' as *;
@use 'pkg:@temperworks/styles/sizes' as *;

.base {
  background: $grey100;
  margin-top: 56px;
  min-height: calc(100vh - 56px);
  padding: env(safe-area-inset-bottom, $doubleOff);

  @include tab {
    margin-top: 64px;
    min-height: calc(100vh - 64px);
    padding: env(safe-area-inset-bottom, $none);
  }
}
</style>
